///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Section/Article */

	section, article {
		z-index: 1000;
		&.special {
			text-align: center;
			z-index: 1000;
		}
	}

	header {
		z-index: 1000;
		p {
			font-family: _font(family-heading);
			font-size: 0.7em;
			font-weight: _font(weight-heading);
			letter-spacing: _font(kerning-heading);
			line-height: 2.5;
			margin-top: -1em;
			text-transform: uppercase;
			z-index: 1000;
		}
	}