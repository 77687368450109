// About page (/about)

.markdown {
  z-index: 1000;

  p {
    margin: auto;
    z-index: 1000;
  }

  h1 {
    font-size: .8em;
    margin-top: 3em;
    z-index: 1000;
  }
}
