///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Blurb */

	.blurb {
		
		// -webkit-backdrop-filter: blur(15px);
		// backdrop-filter: blur(15px);
		background-color: transparent;
		position: relative;
		z-index: 100000;
		
		h2 {
			font-size: 0.8em;
			margin: 0 0 (_size(element-margin) * 0.75) 0;
		}

		h3 {
			font-size: 0.7em;
		}

		p {
			font-size: 0.9em;
		}

		ul {
			z-index: 1000;
		}

		li {
			z-index: 1000;
		}
	}